import { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import useOemService from 'hooks/OemModels/useOemService';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { ESProcedure } from 'components/locations/MappingProcess/Procedures/ESProcedure';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';
import { OemMetadata } from 'hooks/OemModels/MetaModel/BuildOemMetadata';
import { SORT_ORDER, SortOrderClause } from 'enums/SortOrderEnum';
import { match } from 'ts-pattern';

const autoReloadAfterChanges = false;

const useProcedures = (
    filterBy: string[],
    orderBy: SortOrderClause[] | null,
    oemId: number | string,
    includeDeletedProcedures: boolean,
    oemMetadata: OemMetadata,
    dataSource: DataSource
) => {
    const [data, setData] = useState<ESProcedure[]>([]);
    const [proceduresStaleData, setProceduresStaleData] = useState({});
    const [hasMoreData, setHasMoreData] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState({ error: false, value: 0, loadingCount: false, selectableCount: 0 });
    const { oemService } = useOemService(oemId);
    const page = useRef(0);
    const pageSize = 100;
    const { notifications } = useContext(NotificationsContext);

    useEffect(() => {
        setData([]);
        setProceduresStaleData({});
        setHasMoreData(true);
        page.current = 0;
    }, [dataSource, filterBy, orderBy, includeDeletedProcedures]);

    const updateProceduresCount = useCallback(async () => {
        setTotalCount(c => {
            return {
                ...c,
                error: false,
                loadingCount: true,
            };
        });

        try {
            if (dataSource !== DataSource.ES) {
                const countPromise = oemService.countSQLProcedures(filterBy, includeDeletedProcedures);
                const selectableCountPromise = includeDeletedProcedures
                    ? oemService.countSQLProcedures(filterBy, false)
                    : countPromise;
                const [count, selectableCount] = await Promise.all([countPromise, selectableCountPromise]);
                setTotalCount(c => {
                    return {
                        ...c,
                        error: false,
                        value: count,
                        selectableCount,
                        loadingCount: false,
                    };
                });
            }
        } catch (e) {
            setTotalCount(c => {
                return {
                    ...c,
                    error: true,
                    value: undefined,
                    selectableCount: undefined,
                    loadingCount: false,
                };
            });
            throw e;
        }
    }, [dataSource, filterBy, includeDeletedProcedures, oemService]);

    useEffect(() => {
        updateProceduresCount();
    }, [updateProceduresCount]);

    const handleReloadButton = useCallback(() => {
        page.current = 0;
        setHasMoreData(true);
        updateProceduresCount();
        setData([]);
        setProceduresStaleData({});
    }, [updateProceduresCount]);

    const updateProceduresStaleData = useCallback(
        async (newProceduresIds: number[]) => {
            const newStaleData = {};
            for (let i = 0; i < newProceduresIds.length; i += pageSize) {
                const resp = await oemService.getProceduresStaleData(newProceduresIds.slice(i, i + pageSize));
                for (const procedureStaleData of resp.value) {
                    newStaleData[procedureStaleData.procedureId] = procedureStaleData.updateDate;
                }
            }

            setProceduresStaleData(proceduresStaleData => ({ ...proceduresStaleData, ...newStaleData }));
        },
        [oemService]
    );

    const reloadProcedures = async () => {
        setLoading(true);
        try {
            updateProceduresCount();
            let data = [];
            for (let i = 0; i < page.current; i++) {
                if (dataSource === DataSource.ES) {
                    const calculateCount = i === 0;

                    const { totalResults, procedures } = await oemService.getESProcedures(
                        filterBy,
                        matchESOrderBy(oemMetadata, orderBy),
                        pageSize,
                        pageSize * i,
                        includeDeletedProcedures,
                        calculateCount
                    );
                    data = [...data, ...procedures];

                    if (calculateCount) {
                        const selectableCount = includeDeletedProcedures
                            ? await oemService.countESProcedures(filterBy, false)
                            : totalResults;

                        setTotalCount(c => {
                            return {
                                ...c,
                                error: false,
                                value: totalResults,
                                selectableCount: selectableCount,
                                loadingCount: false,
                            };
                        });
                    }
                } else if (dataSource === DataSource.SQL) {
                    const oDataResponse = await oemService.getSQLProcedures(
                        filterBy,
                        matchSQLOrderBy(oemMetadata, orderBy),
                        pageSize,
                        pageSize * i,
                        includeDeletedProcedures
                    );

                    data = [...data, ...oDataResponse.value];
                }
            }

            setHasMoreData(data.length === pageSize * page.current);
            setData(data);
            await updateProceduresStaleData(data.map(p => p.procedureId));
        } catch (error) {
            setData([]);
            setProceduresStaleData({});
            setHasMoreData(false);
            notifications.pushExceptionDanger(error);
        } finally {
            setLoading(false);
        }
    };

    const getProcedureIds = useCallback(
        async (top: number, skip: number): Promise<number[]> => {
            const procedures = (await oemService.getProcedureIds(
                filterBy,
                matchESOrderBy(oemMetadata, orderBy),
                top,
                skip
            )) as ESProcedure[];
            return procedures.map(p => p.procedureId);
        },
        [filterBy, oemMetadata, oemService, orderBy]
    );

    // needs to be updated when bulk mapper will be worked on
    const updateProcedures = procedures => {
        if (autoReloadAfterChanges) {
            return reloadProcedures();
        }

        setData(procedure => {
            const newProcedures = [...procedure];
            procedures.forEach(up => {
                const index = newProcedures.findIndex(np => np.procedureId === up.procedureId);
                newProcedures[index] = up;
            });

            return newProcedures;
        });

        return updateProceduresStaleData(procedures.map(p => p.procedureId));
    };

    const updateOemIqTypeForProcedureIds = (newOemIqType, procedureIds: number[], statusId: number) => {
        if (autoReloadAfterChanges) {
            return reloadProcedures();
        }

        const mappedType = { mappingRuleId: null, mappingStatusId: statusId, typeId: newOemIqType?.value };

        setData(currProcedures => {
            return currProcedures.map(p => {
                if (procedureIds.includes(p.procedureId)) {
                    const newProcedure = { ...p };
                    newProcedure.stageArea.type = mappedType;
                    return newProcedure;
                }
                return p;
            });
        });

        return updateProceduresStaleData(procedureIds);
    };

    const setNewGroupListToProcedureByProcedureId = (newGroupList, procedureId) => {
        if (autoReloadAfterChanges) {
            return reloadProcedures();
        }

        const mappedGroups = newGroupList.map(g => {
            return {
                groupId: g.regionId,
                mappingRuleId: null,
                mappingStatusId: g.mappingWorkFlowStatus.mappingWorkFlowStatusId,
            };
        });

        setData(currProcedures => {
            return currProcedures.map(p => {
                if (p.procedureId === procedureId) {
                    const newProcedure = { ...p };
                    newProcedure.stageArea.groups = mappedGroups;
                    return newProcedure;
                }
                return p;
            });
        });

        return updateProceduresStaleData([procedureId]);
    };

    const loadMoreCallback = useCallback(async () => {
        setLoading(true);
        try {
            if (dataSource === DataSource.ES) {
                const calculateCount = page.current === 0;

                const { totalResults, procedures } = await oemService.getESProcedures(
                    filterBy,
                    matchESOrderBy(oemMetadata, orderBy),
                    pageSize,
                    pageSize * page.current,
                    includeDeletedProcedures,
                    calculateCount
                );

                const esProcedures = procedures as unknown as ESProcedure[];

                setData(d => [...d, ...esProcedures]);

                if (calculateCount) {
                    const selectableCount = includeDeletedProcedures
                        ? await oemService.countESProcedures(filterBy, false)
                        : totalResults;

                    setTotalCount(c => {
                        return {
                            ...c,
                            error: false,
                            value: totalResults,
                            selectableCount: selectableCount,
                            loadingCount: false,
                        };
                    });
                }

                setHasMoreData(procedures.length === pageSize);
                await updateProceduresStaleData(esProcedures.map(p => p.procedureId));
            } else if (dataSource === DataSource.SQL) {
                const oDataResponse = await oemService.getSQLProcedures(
                    filterBy,
                    matchSQLOrderBy(oemMetadata, orderBy),
                    pageSize,
                    pageSize * page.current,
                    includeDeletedProcedures
                );
                const data = oDataResponse.value as unknown as ESProcedure[];

                setData(d => [...d, ...data]);
                setHasMoreData(data.length === pageSize);
                await updateProceduresStaleData(data.map(p => p.procedureId));
            }

            page.current++;
        } catch (error) {
            setData([]);
            setProceduresStaleData({});
            setHasMoreData(false);
            notifications.pushExceptionDanger(error);
        } finally {
            setLoading(false);
        }
    }, [
        dataSource,
        oemService,
        filterBy,
        oemMetadata,
        orderBy,
        includeDeletedProcedures,
        updateProceduresStaleData,
        notifications,
    ]);

    const infusedData = useMemo(
        () =>
            data.map(p => {
                const sqlUpdateDate = proceduresStaleData[p.procedureId];
                const updateDate = p.updateDate.slice(-1) === 'Z' ? p.updateDate : p.updateDate + 'Z';
                return {
                    ...p,
                    updateDate,
                    sqlUpdateDate,
                    isStale: sqlUpdateDate && sqlUpdateDate !== updateDate,
                };
            }),
        [data, proceduresStaleData]
    );

    const matchESOrderBy = (oemMetadata: OemMetadata, orderBy: SortOrderClause[]) => {
        if (orderBy === null) return [];

        const translateSortOrderForES = (sortOrder: SORT_ORDER) =>
            match(sortOrder)
                .with(SORT_ORDER.asc, () => 'Ascending')
                .with(SORT_ORDER.desc, () => 'Descending')
                .with(SORT_ORDER.none, () => '')
                .exhaustive();

        const propertiesId = oemMetadata.properties.map(p => p.id);
        const esOrderBy = orderBy
            .filter(o => propertiesId.includes(o.elementId))
            .map(o => {
                const orderName = oemMetadata.properties.find(p => p.id === o.elementId).orderName;
                return { field: orderName, order: translateSortOrderForES(o.order) };
            });
        return esOrderBy;
    };

    const matchSQLOrderBy = (oemMetadata: OemMetadata, orderBy: SortOrderClause[]) => {
        if (orderBy === null) return '';

        const translateSortOrderForSQL = (sortOrder: SORT_ORDER) =>
            match(sortOrder)
                .with(SORT_ORDER.asc, () => 'asc')
                .with(SORT_ORDER.desc, () => 'desc')
                .with(SORT_ORDER.none, () => '')
                .exhaustive();

        const propertiesId = oemMetadata.properties.map(p => p.id);
        const sqlOrderBy = orderBy
            .filter(o => propertiesId.includes(o.elementId))
            .map(o => {
                const orderName = oemMetadata.properties.find(p => p.id === o.elementId).orderName;
                return `${orderName.replaceAll('.', '/')} ${translateSortOrderForSQL(o.order)}`;
            })
            .join(',');
        return sqlOrderBy;
    };

    return {
        data: infusedData,
        hasMoreData,
        loading,
        totalCount,
        loadMoreCallback,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        refreshProcedures: () => {}, // this is old code from mapper
        handleReloadButton,
        updateProcedures,
        updateOemIqTypeForProcedureIds,
        setNewGroupListToProcedureByProcedureId,
        getProcedureIds,
    };
};

export default useProcedures;
