import { PROPERTY_TYPE } from './PropertyType';
import { buildProcedureProperty } from './ProcedureProperty';
import { MappingRuleId, MappingStatus, VehicleYearList, ProcedureHTML } from 'components/Shared/TableFilters/Types';
import { Group } from 'api/RepairProcedures/types';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';

export const getCommonProperties = (dataSource: DataSource) => [
    buildProcedureProperty(
        {
            name: 'procedureId',
            displayName: 'Rp Procedure Id',
            type: PROPERTY_TYPE.historyView,
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            id: 'firstBookId',
            name: 'booksForProcedure.0.book.bookId',
            displayName: 'Book Id',
            type: PROPERTY_TYPE.bookId,
            sortable: false,
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            id: 'firstBookName',
            name: 'booksForProcedure.0.book.bookName',
            displayName: 'Book name',
            type: PROPERTY_TYPE.bookName,
            sortable: false,
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: 'vehicles',
            displayName: 'Vehicles',
            type: PROPERTY_TYPE.vehicleView,
            sortable: false,
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: 'procedureTitle',
            displayName: 'Procedure Title',
            type: PROPERTY_TYPE.procedureView,
        },
        dataSource
    ),
    buildProcedureProperty<Group[]>(
        {
            name: 'stageArea.groups',
            displayName: 'Group Ids',
            multiple: true,
            type: PROPERTY_TYPE.groupsIds,
            sortable: false,
        },
        dataSource
    ),
    buildProcedureProperty<Group[]>(
        {
            id: 'groupsView',
            name: 'stageArea.groups',
            displayName: 'Groups',
            type: PROPERTY_TYPE.groupView,
            sortable: false,
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            id: 'TypeId',
            name: 'stageArea.type.typeId',
            displayName: 'Type Id',
            type: PROPERTY_TYPE.typeId,
            sortable: false,
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            id: 'TypeName',
            name: 'stageArea.type.typeId',
            displayName: 'Type Name',
            type: PROPERTY_TYPE.typeName,
            sortable: false,
        },
        dataSource
    ),
];

export const getLastCommonProperties = (dataSource: DataSource) => {
    const commonProperties = [
        buildProcedureProperty(
            {
                id: 'SqlUpdateDate',
                name: 'sqlUpdateDate',
                displayName: 'SQL Update Date',
                type: PROPERTY_TYPE.gmtDate,
                sortable: false,
                searchable: false,
            },
            dataSource
        ),
    ];

    const additionalProperties =
        dataSource === DataSource.ES
            ? [
                  buildProcedureProperty(
                      {
                          id: 'UpdateDate',
                          name: 'updateDate',
                          displayName: 'ES Index Update Date',
                          type: PROPERTY_TYPE.gmtDate,
                          sortable: true,
                      },
                      dataSource
                  ),
                  buildProcedureProperty(
                      {
                          id: '_timestamp',
                          name: '_timestamp',
                          displayName: 'Upsert Timestamp',
                          type: PROPERTY_TYPE.gmtDate,
                          sortable: true,
                      },
                      dataSource
                  ),
              ]
            : [];

    return [...commonProperties, ...additionalProperties];
};

export const getCommonFilters = (dataSource: DataSource) => {
    const commonFilters = [
        {
            id: 'mappingRuleId',
            label: 'Mapping Rule Id',
            allowInstances: 10,
            property: 'stageArea',
            ruleable: false,
            type: MappingRuleId,
        },
        {
            id: 'MappingStatus',
            label: 'Procedure Mapping Status',
            allowInstances: 10,
            property: '',
            ruleable: false,
            type: MappingStatus,
        },
        {
            id: 'VehicleYear',
            label: 'Vehicle Year',
            allowInstances: 10,
            property: 'vehicles',
            ruleable: false,
            type: VehicleYearList,
        },
    ];

    const additionalFilters =
        dataSource === DataSource.ES
            ? [
                  {
                      id: 'ProcedureHTML',
                      label: 'Procedure HTML',
                      allowInstances: 10,
                      property: 'html',
                      ruleable: false,
                      type: ProcedureHTML,
                  },
              ]
            : [];

    return [...commonFilters, ...additionalFilters];
};
