import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactModal, PrimaryButton, LightButton } from 'oemiq-common';
import { isEmpty } from 'lodash';
import BuildMappingRuleModalBody from './BuildMappingRuleModalBody';
import useOemService from 'hooks/OemModels/useOemService';
import SmallSpinner from 'components/SmallSpinner';
import { TemporaryFlags } from '../../temporaryFlags';

const mapFiltersToConditions = (oemMetadata, filters) => {
    return oemMetadata.ruleable.reduce((acc, p) => {
        const filter = filters.find(f => f.property == p.name);
        acc[p.rulePropertyName] = filter ? { value: filter.value, operator: filter.operator } : null;
        return acc;
    }, {});
};

const BuildMappingRuleModal = ({
    isModalOpen,
    setModalOpen,
    filters,
    proceduresCount,
    mappingDefinitions,
    oemMetadata,
    onCreateMappingRule,
}) => {
    const conditions = useMemo(() => mapFiltersToConditions(oemMetadata, filters), [oemMetadata, filters]);

    const [typeId, setTypeId] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const rule = useMemo(() => {
        return {
            ...Object.keys(conditions).reduce((acc, p) => {
                acc[p] = conditions[p]
                    ? { value: conditions[p].value, operator: conditions[p].operator.ruleableValue }
                    : null;
                return acc;
            }, {}),
            typeId,
            groupIds: !isEmpty(selectedGroups) ? selectedGroups.map(g => g.value) : null,
            procedureTypeId: TemporaryFlags.procedureTypeIdForProcedure,
        };
    }, [conditions, selectedGroups, typeId]);

    const clearState = useCallback(() => {
        setTypeId(null);
        setSelectedGroups([]);
    }, []);
    const closeModal = useCallback(() => {
        clearState();
        setModalOpen(false);
    }, [setModalOpen, clearState]);
    const handleCreate = useCallback(() => {
        closeModal();
        onCreateMappingRule(rule);
    }, [onCreateMappingRule, closeModal, rule]);

    const { oemId } = useParams();
    const { oemEngineService } = useOemService(oemId);

    return (
        <ReactModal
            isOpen={isModalOpen}
            toggle={useCallback(() => setModalOpen(!isModalOpen), [setModalOpen, isModalOpen])}
            headerComponent={'Create New Mapping Rule'}
            bodyComponent={
                <BuildMappingRuleModalBody
                    conditions={conditions}
                    oemMetadata={oemMetadata}
                    mappingDefinitions={mappingDefinitions}
                    selectedGroups={selectedGroups}
                    typeId={typeId}
                    onGroupsChange={setSelectedGroups}
                    onTypeIdChange={setTypeId}
                />
            }
            footerComponent={
                <div className="d-flex flex-fill">
                    <span className="flex-fill align-self-center">
                        <>
                            {proceduresCount.loadingCount ? (
                                <SmallSpinner />
                            ) : (
                                `${proceduresCount.value} procedures meet current criteria`
                            )}
                        </>
                    </span>
                    <LightButton id="build-mapping-rule-modal-cancel" className="me-3" onClick={closeModal}>
                        Cancel
                    </LightButton>
                    <PrimaryButton
                        id="build-mapping-rule-modal-submit"
                        disabled={!oemEngineService.isRuleValid(rule)}
                        onClick={handleCreate}>
                        Create rule
                    </PrimaryButton>
                </div>
            }
        />
    );
};

export default BuildMappingRuleModal;
